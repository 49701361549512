/* SidebarMenu */
.sidebar-menu {
  position: fixed;
  top: 0;
  left: -300px;
  width: 300px;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.75);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: left 0.3s ease;
  z-index: 1000;
  color: white;
  display: flex;
  flex-direction: column;
}

.sidebar-menu.open {
  left: 0;
}

.sidebar-header {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: white;
}

.sidebar-content {
  padding: 20px;
}

.sidebar-content .nav-links {
  display: flex;
  flex-direction: column;
}

.sidebar-content .nav-links a,
.sidebar-content .nav-links .link {
  margin: 10px 0;
}

/* Header */
.header {
  color: white;
  text-align: center;
  padding: 20px;
  height: 20%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.hamburger-menu {
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  left: 20px;
}

.title {
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav-links {
  display: none;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  user-select: none;
  overflow: hidden;
}

.nav-links {
  margin-top: 10px;
}

.nav-links a,
.nav-links .link {
  color: white;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 5px;
  border: 1px solid white;
  text-decoration: none;
  margin: 0 10px;
  padding: 10px;
  font-size: 1.1em;
}

.nav-links a:hover,
.nav-links .link:hover {
  background-color: rgb(255, 255, 255);
  color: black;
}

.main {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.map-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: grab;
}

.map-container:active {
  cursor: grabbing;
}

.dimension {
  width: 200px;
  height: 100px;
  background-color: rgba(255, 255, 255, 0.1);
  color: white;
  border-radius: 10px;
  border: 1px solid white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
  transition: background-color 0.3s, color 0.3s;
}

.dimension.dimmed {
  background-color: black;
  color: rgb(29, 26, 26);
}

.dimension .label {
  font-weight: bold;
  margin-bottom: 5px;
  text-align: center;
}

.dimension .separator {
  width: 100%;
  height: 1px;
  background-color: white;
  margin-bottom: 5px;
}

.dimension .largeLabel {
  font-size: 0.9em;
  color: #ccc;
  text-align: center;
}

.dimension.frontrooms {
  background-color: rgba(200, 255, 168, 0.1);
  border: 1px solid rgb(200, 255, 168);
}

.dimension.frontrooms.selected {
  background-color: rgba(200, 255, 168, 1);
}

.dimension.frontrooms .label {
  margin-bottom: 0;
}

.dimension.frontrooms .separator,
.dimension.frontrooms .largeLabel {
  display: none;
}

.connections {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
}

.connections line {
  filter: url(#glow);
}

.connections .dimmed {
  stroke: rgb(29, 26, 26);
  filter: none;
}

.react-flow__edge-text {
  font-size: 16px;
  fill: white;
}

.react-flow__edge-path {
  stroke: white;
}

.react-flow__edge-path.dimmed {
  stroke: rgba(28, 28, 28, 0.5);
}

.react-flow__edge-arrow {
  fill: white;
}

.react-flow__edge-arrow.dimmed {
  fill: rgba(28, 28, 28, 0.5);
}

.react-flow__handle {
  width: 10px;
  height: 10px;
  background: transparent;
  border: none;
}

.react-flow__attribution {
  display: none;
}

.legend {
  position: absolute;
  bottom: 20px;
  right: 30px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
  border-radius: 8px;
  color: white;
}

.legend-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.legend-color {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
  border-radius: 3px;
}

.legend-text {
  font-size: 14px;
}

.legend-item:last-child {
  margin-bottom: 0;
}

.timeline-container {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;
  color: white;
}

.card.type-1 {
  background-color: turquoise;
  border: 1px solid white;
  color: white;
}

.card.type-2 {
  background-color: orange;
  border: 1px solid white;
  color: white;
}

.card.type-3 {
  background-color: red;
  border: 1px solid white;
  color: white;
}

.pathfinder-container {
  width: 100%;
  height: 100%;
  color: white;
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.pathfinder-header {
  margin-bottom: 20px;
}

.pathfinder-select-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
}

.pathfinder-select {
  width: 45%;
  margin-bottom: 20px;
}

.pathfinder-button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.pathfinder-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid white;
  color: white;
  border-radius: 5px;
}

.pathfinder-no-path {
  margin-top: 20px;
  color: red;
}

.pathfinder-path-container {
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  padding: 0 50px;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: white transparent;
}

.pathfinder-path {
  width: 100%;
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px;
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}

.pathfinder-node {
  position: relative;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pathfinder-node-label {
  font-weight: bold;
  margin-bottom: 5px;
}

.pathfinder-node-separator {
  width: 100%;
  height: 1px;
  background-color: white;
  margin-bottom: 5px;
}

.pathfinder-node-largeLabel {
  font-size: 0.9em;
  color: #ccc;
}

.pathfinder-detail-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-around;
  padding-bottom: 10px;
}

.pathfinder-node-logo {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 25px;
  height: 25px;
}

.pathfinder-details-button {
  margin-top: 20px;
  padding: 10px 20px;
  font-size: 16px;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid white;
  color: white;
  border-radius: 5px;
}

.pathfinder-details-container {
  margin-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
  box-sizing: border-box;
}

.pathfinder-details-item {
  border: 1px solid white;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
}

.react-select__control {
  background-color: white;
  border-color: white;
  color: black;
}

.react-select__option {
  color: black;
  background-color: darkgray;
}

.react-select__option--is-selected {
  color: white;
  background-color: darkgray;
}

.react-select__option--is-focused {
  background-color: lightgray;
}

.react-select__single-value {
  color: black;
}

.react-select__menu {
  background-color: darkgray;
  border: 1px solid white;
}

.react-select__placeholder {
  color: black;
}

.react-select__input {
  color: black;
}

.node-type-1 {
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.1);
}

.node-type-2 {
  border: 1px solid rgb(64, 48, 186);
  background-color: rgba(0, 0, 255, 0.1);
}

.node-type-3 {
  border: 1px solid rgb(186, 48, 48);
  background-color: rgba(255, 0, 0, 0.1);
}

.node-type-4 {
  border: 1px solid rgb(44, 122, 65);
  background-color: rgba(44, 122, 65, 0.1);
}

.node-type-default {
  border: 1px solid white;
  background-color: rgba(255, 255, 255, 0.1);
}

.pathfinder-path-container ::-webkit-scrollbar {
  width: 8px;
}

.pathfinder-path-container ::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
}

.pathfinder-path-container ::-webkit-scrollbar-track {
  background: transparent;
}

.react-select__control {
  background-color: white;
  border-color: white;
  color: black;
  height: 40px;
  /* Ajustez la hauteur ici */
}

.react-select__option {
  color: black;
  background-color: darkgray;
}

.react-select__option--is-selected {
  color: white;
  background-color: darkgray;
}

.react-select__option--is-focused {
  background-color: lightgray;
}

.react-select__single-value {
  color: black;
}

.react-select__menu {
  background-color: darkgray;
  border: 1px solid white;
}

.react-select__placeholder {
  color: black;
}

.react-select__input {
  color: black;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: white;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background: transparent;
}